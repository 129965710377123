import React, {useEffect, useState} from 'react';
import {Container, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import Card from "@material-ui/core/Card";
import EnhancedTable from "./ReactMatTable";
import {useStyles} from '../../../constants/style';

const AppInstalls = ({className}) => {
  const classes = useStyles();

  const pageURL = window.location.href;
  const lastSeg = pageURL.substr(pageURL.lastIndexOf('/') + 1);
  const [ruleId] = useState(lastSeg);


  return (
    <Page
      className={classes.root}
      title="Sms Logs"
    >
      <Container maxWidth={false}>
        <Card style={{marginBottom: '20px'}}>
          <EnhancedTable/>
        </Card>
      </Container>
    </Page>
  );
};

export default AppInstalls;
