import React, {useState} from 'react';
import Inputs from './input';
import {Box, Button, Card, Container, makeStyles, Paper, Snackbar,} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import Page from 'src/components/Page';
import axios from "../../../utils/axios"

import {servicePath} from '../../../constants/index';
import {useStyles} from '../../../constants/style';

const MessageConfig = () => {
  const classes = useStyles();
  const [openSnack, setOpenSnack] = useState(false);
  const blankCat = {key: '', en: '', ar: '', en_coin: '', ar_coin: '', disableField: false};
  const [catState, setCatState] = useState([
    {...blankCat},
  ]);
  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${servicePath}/getConfigMessage`);
      if (result.data.length > 0) {
        let i = 0;
        result.data.forEach(msg => {
          for (let msgkey in msg) {
            let msgVal = msg[msgkey]?.value;
            let msgValCoin = msg[msgkey]?.value_coin;
            let msgtext = {
              key: msgkey,
              en: msgVal?.en,
              ar: msgVal?.ar,
              en_coin: msgValCoin?.en,
              ar_coin: msgValCoin?.ar,
              ruleType: msg[msgkey]?.ruleType,
              userType: msg[msgkey]?.userType,
              disableField: true
            }
            catState[i] = msgtext;
            setCatState([...catState]);
          }
          i++;
        });
      }

    };
    fetchData();
  }, []);

  const updateCat = async (index) => {
    const msgData = catState[index];
    const result = await axios.post(`${servicePath}/configMessage`, {
      "key": msgData.key,
      "value": {"ar": msgData.ar, "en": msgData.en},
      "value_coin": {"ar": msgData.ar_coin, "en": msgData.en_coin},
      "userType": msgData.userType,
      "ruleType": msgData.ruleType
    });
    if (result.data.status) {
      setOpenSnack(true);
    } else {
      setOpenSnack(false);
    }
  }
  const addCat = () => {
    setCatState([...catState, {...blankCat}]);
  };
  const removeCat = (index) => {
    catState.splice(index, 1);
    setCatState([...catState]);
  }
  const handleCatChange = (e) => {
    console.log(e.target, "e.target", e.target.value ,e.target.dataset.classname , e.target.dataset.idx)
    let updatedCats = [...catState];
    updatedCats[e.target.dataset.idx][e.target.dataset.classname] = e.target.value;
    setCatState(Object.assign([], updatedCats));
  };

  return (
    <Page
      className={classes.root}
      title="Message Configuration"
    >
      <Snackbar
        autoHideDuration={2000}
        open={openSnack}
        onClose={() =>  setOpenSnack(false)}
        closeFunc={() => {
          setOpenSnack(false)
        }}
        message="Update successful.">
        <Alert onClose={() => {
          setOpenSnack(false)
        }} severity="success">
          <Box style={{fontWeight: "bold"}}> Update successful</Box>
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <Card style={{marginBottom: '20px'}}>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Box
                display="flex"
                justifyContent="space-between"
                p={2}
              >
                <div style={{  color: "#546e7a",
                  fontFamily: "'Roboto'",
                  fontWeight: 500,
                  fontSize: "20px"}}>Configurable Messages</div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={addCat}
                >
                  Add New
                </Button>
              </Box>
              <Box style={{paddingBottom: "1px"}}>


              {
                catState.map((val, idx) => (
                  <Inputs
                    key={`cat-${idx}`}
                    idx={idx}
                    catState={catState}
                    removeCat={(idx) => removeCat(idx)}
                    updateCat={(idx) => updateCat(idx)}
                    handleCatChange={handleCatChange}
                  />
                ))
              }
              </Box>
            </Paper>
          </div>
        </Card>
      </Container>
    </Page>
  );
};


export default MessageConfig;
