import {lighten, makeStyles} from '@material-ui/core/styles';
export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%', color: "#546e7a",
    fontFamily: "'Roboto'",
    fontWeight: 500,
    fontSize: "20px",
    padding: "20px 0"
  },
}));