import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
// Icons
import EditIcon from '@material-ui/icons/EditOutlined';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import axios from "../../utils/axios";
import {GlobalFilterCtx} from "../../App";
import {servicePath} from "../../constants";
import Page from "../../components/Page";

const useStyles = makeStyles(theme => ({
  subTitle: {
    flex: '1 1 100%',
    color: '#546e7a',
    fontFamily: "'Roboto'",
    paddingBottom: '20px',
    paddingLeft: '10px'
  },
  title: {
    flex: '1 1 100%',
    color: '#546e7a',
    fontFamily: "'Roboto'",
    fontWeight: 500,
    fontSize: '20px',
    paddingLeft: '10px'
  },
  root: {
    minHeight: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40
  },
  input: {
    width: 130,
    height: 40
  }
}));

const createData = (name, calories, fat, carbs, protein) => ({
  id: name.replace(' ', '_'),
  name,
  calories,
  fat,
  carbs,
  protein,
  isEditMode: false
});

const processImage = (url) => {
  return <img src={url} width="320" height="240" />
}

const processVideo = url => {
  return (
    <video width="320" height="240" controls>
      <source
        src={`${url}
    `}
        type="video/mp4"
      />
      <source src={`${url}`} type="video/ogg"/>
      Your browser does not support the video tag.
    </video>
  );
};
const CustomTableCell = ({row, name, onChange}) => {
  const classes = useStyles();
  const {isEditMode} = row;

  const processRow = name => {
    if (name === 'ProductSkus') {
      return row[name]?.join(', ');
    }
    if (name === 'VideoUrl') {
      return processVideo(row[name]);
    }
    if (name === 'VideoThumbnail') {
      return processImage(row[name]);
    }

    return row[name];
  };
  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        processRow(name)
      )}
    </TableCell>
  );
};

function StyliEarnVideos() {
  const [rows, setRows] = React.useState([
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0)
  ]);
  const [previous, setPrevious] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [pQuery, setPQuery] = React.useState('');
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const {store, date} = useContext(GlobalFilterCtx);

  const classes = useStyles();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const result = await axios.post(`${servicePath}/getReferralVideos`, {
        from: `${page}`,
        size: `${rowsPerPage}`,
        query: pQuery
      });
      result && setRows(result.data.referral_videos);
      result && setTotalCount(result.data.total_count);
    };

    fetchData();
    setIsLoading(false);
  }, [page, rowsPerPage, store, date, pQuery]);

  const onToggleEditMode = id => {
    setRows(state => {
      return rows.map(row => {
        if (row.VideoId === id) {
          return {...row, isEditMode: !row.isEditMode};
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.VideoId]) {
      setPrevious(state => ({...state, [row.VideoId]: row}));
    }
    const {value} = e.target;
    const {name} = e.target;
    const {VideoId} = row;
    const newRows = rows.map(VideoId => {
      if (row.VideoId === VideoId) {
        return {...row, [name]: value};
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = VideoId => {
    const newRows = rows.map(row => {
      if (row.VideoId === VideoId) {
        return previous[VideoId] ? previous[VideoId] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious(state => {
      delete state[VideoId];
      return state;
    });
    onToggleEditMode(VideoId);
  };

  return (
    <Page className={classes.root} title="Styli Earn Videos">
      <Paper className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Styli Earn Videos
        </Typography>
        <Typography
          className={classes.subTitle}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Showing {totalCount} records
        </Typography>
        <Table className={classes.table} aria-label="caption table">
          <caption/>
          <TableHead>
            <TableRow>
              <TableCell align="left"/>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Product Skus</TableCell>
              <TableCell align="left">Added On</TableCell>
              <TableCell align="left">Video Url</TableCell>
              <TableCell align="left">Video Thumbnail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.VideoId}>
                <TableCell className={classes.selectTableCell}>
                  {row.isEditMode ? (
                    <>
                      <IconButton
                        aria-label="done"
                        onClick={() => onToggleEditMode(row.VideoId)}
                      >
                        <DoneIcon/>
                      </IconButton>
                      <IconButton
                        aria-label="revert"
                        onClick={() => onRevert(row.VideoId)}
                      >
                        <RevertIcon/>
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => onToggleEditMode(row.VideoId)}
                    >
                      <EditIcon/>
                    </IconButton>
                  )}
                </TableCell>
                <CustomTableCell {...{row, name: 'Title', onChange}} />
                <CustomTableCell {...{row, name: 'ProductSkus', onChange}} />
                <CustomTableCell {...{row, name: 'AddedOn', onChange}} />
                <CustomTableCell {...{row, name: 'VideoUrl', onChange}} />
                <CustomTableCell
                  {...{row, name: 'VideoThumbnail', onChange}}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
}

export default StyliEarnVideos;
