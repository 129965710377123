import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Card, Divider, Grid, makeStyles, TextareaAutosize, TextField} from '@material-ui/core';
import ConfirmDialog from '../../../utils/confirmDialog';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  capitalize: {textTransform: "capitalize"},
  tableRow: {
    cursor: "pointer",
  },
  disabledText: {
    fontSize: "14px",

  }
}));
const Inputs = ({idx, catState, handleCatChange, removeCat, updateCat}) => {
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const enId = `en-${idx}`;
  const arId = `ar-${idx}`;
  // const enCoinId = `en_coin-${idx}`;
  // const arCoinId = `ar_coin-${idx}`;
  return (
    <Card key={`key-${idx}`} style={{margin: "20px 20px 40px 20px", background: "#f3f3f3", boxShadow: "5px 5px 5px #aaaaaa"}}>
      <Grid
        className={classes.item}
        item
        style={{padding: "20px"}}
      >
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h6"
        >
          Message Key
        </Typography>
        <TextField
          type="text"
          name={`key-${idx}`}
          inputProps={{
            "placeholder": 'Message Key', "data-idx": `${idx}`, "data-classname": "key"
          }}
          value={catState[idx].key}
          disabled={catState[idx].disableField ? true : false}
          onChange={handleCatChange}
          required
          variant="outlined"
          InputProps={{
            className: classes.disabledText,
          }}
        />
      </Grid>
      <Divider/>
      <Grid
        className={classes.item}
        item
        style={{padding: "20px"}}
      >
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h6"
        >
          English
        </Typography>
        <TextareaAutosize
          style={{
            fontSize: "15px",
            fontFamily: "Helvetica",
            fontWeight: "500",
            width: "100%",
            padding: "10px",
            marginTop: "0px",
            color: "rgb(80 79 79)",
            borderColor: "#c1bfbf",
            borderRadius: "5px"
          }}
          rowsMin={3}
          name={enId}
          data-idx={idx}
          data-classname="en"
          id={enId}
          className="en"
          value={catState[idx].en}
          onChange={handleCatChange}
          placeholder="Refer your friends and get SAR 10 on every friend who installs the app."
          required
          variant="outlined"
        />
      </Grid>
      <Grid
        className={classes.item}
        item
        style={{padding: "20px"}}
      >
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h6"
        >
          Arabic
        </Typography>
        {console.log(catState[idx], "catState[idx]catState[idx]catState[idx]")}
        <TextareaAutosize
          style={{
            fontSize: "15px",
            fontFamily: "Helvetica",
            fontWeight: "500",
            width: "100%",
            padding: "10px",
            marginTop: "0px",
            color: "rgb(80 79 79)",
            borderColor: "#c1bfbf",
            borderRadius: "5px"
          }}
          rowsMin={3}
          name={arId}
          data-idx={idx}
          id={arId}
          data-classname="ar"
          value={catState[idx].ar}
          onChange={handleCatChange}
          placeholder="شسيبشنس  شسيب  شسبش يوبي."
          required
          variant="outlined"
        />
      </Grid>
      <Divider/>
      {catState[idx].key == "sms_text" ?
        <Grid style={{display: "flex", flexDirection: "row", padding: "20px"}}>
          <div style={{width: "50%"}}>
            <TextField style={{fontSize: "14px"}} fullWidth label="Rule Type" data-idx={idx} data-classname="ruleType"
                       id={"ruleType"}
                       name={"ruleType"}
                       onChange={handleCatChange}
                       value={catState[idx].ruleType}
                       inputProps={{'data-idx': idx, "data-classname": "ruleType", style: {fontSize: "14px"}}}

            />
          </div>
          <div style={{width: "50%"}}>
            <TextField fullWidth label="User Type" data-idx={idx} data-classname="userType" id={"userType"}
                       name={"userType"}
                       onChange={handleCatChange}
                       value={catState[idx].userType}
                       inputProps={{'data-idx': idx, "data-classname": "userType", style: {fontSize: "14px"}}}
            />
          </div>
        </Grid>
      : ""}
      <Divider/>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => updateCat(idx)}
          style={{marginRight: "5px"}}
          className={classes.button}
          startIcon={<SaveIcon/>}
        >
          Save
        </Button>
        <Button
          variant="contained"
          style={{backgroundColor: "#DC004E", color: "#fff"}}
          className={classes.button}
          startIcon={<DeleteIcon/>}
          onClick={() => setConfirmOpen(true)}
        >
          Remove
        </Button>

        <ConfirmDialog
          title="Delete this message?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={() => removeCat(idx)}
        >
          Are you sure you want to delete this message?
        </ConfirmDialog>
      </Box>
    </Card>
  );
};

Inputs.propTypes = {
  idx: PropTypes.number,
  catState: PropTypes.array,
  handleCatChange: PropTypes.func,
};

export default Inputs;
