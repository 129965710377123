import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import axios from "../../../utils/axios"

import {servicePath} from '../../../constants/index';
import {GlobalFilterCtx} from "../../../App";
import moment from 'moment';
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import {storeMapper} from "../CreditHistory/ReactMatTable";
import {useStyles} from '../../../constants/reactctMatTableStyle';
import {useToolbarStyles} from '../../../constants/reactMatTableToolbarStyle';
const headCells = [
  {id: 'deviceId', numeric: false, disablePadding: true, label: 'Device Id'},
  {id: 'referrerUserId', numeric: true, disablePadding: false, label: 'Referrer UserId'},
  {id: 'refCode', numeric: true, disablePadding: false, label: 'Referral Code'},
  {id: 'refStore', numeric: true, disablePadding: false, label: 'Store'},
  {id: 'refMobile', numeric: true, disablePadding: false, label: 'Referrer Mobile'},
  {id: 'refEmail', numeric: true, disablePadding: false, label: 'Referrer Email'},
  {id: 'refereeUserId', numeric: true, disablePadding: false, label: 'Referee UserId'},
  {id: 'added_on', numeric: true, disablePadding: false, label: 'Added On'},
];

function EnhancedTableHead(props) {
  const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
              {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const deleteSelected = async (selected) => {
  console.log(selected, "Delete Selected");
  alert('Disabled Currently');
}

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {numSelected, selected} = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          App Installs
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [userDeviceMap, setUserDeviceMap] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRuleMeta] = React.useState([]);
  const {store, date} = useContext(GlobalFilterCtx);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(`${servicePath}/getAppInstalls/`, {from: '' + page, size: '' + rowsPerPage});
      result && setRuleMeta(result.data.app_installs);
      result && setTotalCount(result.data.total_count);
      result && setUserDeviceMap(result.data.user_device_map);
      setIsLoading(false);
    };
    setIsLoading(true);

    fetchData();
  }, [page, rowsPerPage, store, date]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.deviceId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} selected={selected}/>
        {isLoading && <Box style={{position: "absolute", top: "50%", left: "50%"}}><CircularProgress/></Box>}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {rows
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.deviceId);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const refereeUserId = userDeviceMap?.[row.deviceId];

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.deviceId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.deviceId}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        {row.deviceId}
                      </TableCell>
                      <TableCell align="left">{row.referrerUserId}</TableCell>
                      <TableCell align="left">{row.refCode}</TableCell>
                      <TableCell align="left" style={{textTransform: "uppercase"}}>{storeMapper[row.store]}</TableCell>
                      <TableCell align="left">{row.referrerMobile}</TableCell>
                      <TableCell align="left">{row.referrerUserEmail}</TableCell>
                      <TableCell align="left">{refereeUserId}</TableCell>
                      <TableCell align="left">{moment(row.added_on).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
