import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, makeStyles} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import axios from "../../../utils/axios"

import Alert from "@material-ui/lab/Alert";
import {servicePath} from '../../../constants/index';

import _ from "lodash"
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    fullWidth: true,
    minWidth: 120,
    display: 'flex',
    wrap: 'nowrap'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
const TermsRow = ({termRow, handleChange, index}) => {


  return <div style={{"background": "#f3f3f3", "borderRadius": "8px", padding: "20px", margin: "20px 0"}}>
    <Typography
      color="textPrimary"
      gutterBottom
      variant="h6"
      style={{marginBottom: "20px"}}
    >
      (Key) {index + 1}
    </Typography>
    <div item style={{display: "flex", padding: "10px", margin: "20px 0"}}>
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h6"
        style={{marginBottom: "0px", width: "12%"}}
      >
        Title
      </Typography>
      <TextField
        style={{padding: "5px"}}
        fullWidth
        label="Title English"
        name="TitleEng"
        onChange={(e) => handleChange(index, 'Title', 'en', e.target.value)}
        required
        value={termRow.Title?.en}
        variant="outlined"
      />
      <TextField
        style={{padding: "5px"}}
        fullWidth
        label="Title Ar"
        name="Title Arabic"
        onChange={(e) => handleChange(index, 'Title', 'ar', e.target.value)}

        required
        value={termRow.Title?.ar}
        variant="outlined"
      />

    </div>
    <div item style={{display: "flex", padding: "10px", margin: "20px 0"}}>
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h6"
        style={{marginBottom: "0px", width: "12%"}}
      >
        Subtitle
      </Typography>
      <TextField
        style={{padding: "5px"}}
        fullWidth
        label="Subtitle En"
        name="Subtitle English"
        onChange={(e) => handleChange(index, 'Subtitle', 'en', e.target.value)}
        required
        value={termRow.Subtitle?.en}
        variant="outlined"
      />
      <TextField
        style={{padding: "5px"}}
        fullWidth
        label="Subtitle Arabic"
        name="Subtitle Arabic"
        onChange={(e) => handleChange(index, 'Subtitle', 'ar', e.target.value)}
        required
        value={termRow.Subtitle?.ar}
        variant="outlined"
      />

    </div>
  </div>
}

const Terms = ({className, ruleMeta, code, ...rest}) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [conditionType, setConditionType] = useState("");
  const [termsArr, setTermsArr] = useState(ruleMeta?.Terms?.[code]);
  const [openSnack, setOpenSnack] = useState(false);

  const handleChange = async(index, type, lang, val) => {
    console.log(index, type, lang, val, '-----index, type, lang, val----')
    const tmpTermsArr = [...termsArr];
    tmpTermsArr[index][type][lang] = val;
    setTermsArr(tmpTermsArr);

  };

  const saveRuleInfo = async () => {
    //Update Backend
    const updReq = {...ruleMeta};
    updReq.Terms[code] = termsArr;
    updReq['ruleId'] = ruleMeta.ID;
    const result = await axios.post(`${servicePath}/saveRuleInfo`, updReq);

    if (result.status) {
      setOpenSnack(true)
    }
  }


  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        open={openSnack}
        onClose={() =>  setOpenSnack(false)}
        closeFunc={() => {
          setOpenSnack(false)
        }}
        message="Update successful.">
        <Alert onClose={() => {
          setOpenSnack(false)
        }} severity="success">
          <Box style={{fontWeight: "bold"}}> Update successful</Box>
        </Alert>
      </Snackbar>

      <form
        className={clsx(classes.root, className)}
        {...rest}
      >

        <Card>
          <Divider/>
          <CardContent style={{padding: "30px"}}>
            <Grid spacing={6} wrap="wrap">
              <div wrap="wrap" style={{margin: "20px 0"}}>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                  style={{marginBottom: "20px"}}
                >
                  Country (<span style={{textTransform:"uppercase"}}>{code}</span>)
                </Typography>
                {termsArr?.map((termRow, k) => {
                  return <TermsRow key={k} index={k} termRow={termRow} handleChange={handleChange}/>
                })}
              </div>

            </Grid>
          </CardContent>
          <Divider/>
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={saveRuleInfo}
            >
              Save
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};

export default Terms;
