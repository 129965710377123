import React, { useState,useEffect} from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import { authServicePath,servicePath} from '../../constants/index';
import axios from 'axios';
import { setCurrentUser, getCurrentUser} from 'src/utils/helper';
const domain = new URL(servicePath).hostname;


const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  form: {
    width: '500px',
    textAlign: 'center',
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
}));

const OTPForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    otp: '',
  });

  const [errors, setErrors] = useState({
    otp: '',
  });

  useEffect(() => {	
    const checkUser = async () => {	
      // Check local storage for user info	
      const localStorageUser = localStorage.getItem('email');	
      
      // Check cookies for user info	
      // Replace 'getCookie' with your actual function to retrieve cookies	
      const cookieUser = getCurrentUser();	
      console.log("cookieUser,",cookieUser)

      // If user info exists in either local storage or cookies, do nothing (continue)	
      if (localStorageUser || cookieUser) {	
        return;	
      }	

      // If no user info found in either local storage or cookies, redirect to login	
      navigate('/login');	
    };	

    checkUser();	
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate OTP format (only numeric characters)
    const numericRegex = /^[0-9]*$/;
    if (!numericRegex.test(value)) {
      setErrors({
        ...errors,
        [name]: 'Please enter only numeric characters.',
      });
    } else {
      setErrors({
        ...errors,
        [name]: '', // Clear error message if format is correct
      });
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const verifyOTP = async (otp, email,domain) => {
    try {
      const response = await axios.post(
        `${authServicePath}/verify-otp`,
        { otp, email, domain},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('result:::', response.data);
      return response.data;
    } catch (error) {
      console.error('Error verifying OTP:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Perform validation
    if (values.otp.length === 0) {
      setErrors({
        ...errors,
        otp: 'Please enter your OTP',
      });
      return;
    } else if (values.otp.length < 6) {
      setErrors({
        ...errors,
        otp: 'OTP must be 6 characters long',
      });
      return;
    }

    try {
      // const userItem = localStorage.getItem('ivy_user_info');
      // const userObject = JSON.parse(userItem);
      const userEmail = localStorage.getItem('email');
      const response = await verifyOTP(values.otp, userEmail,domain);

      if (response.status) {
        // Set cookie
        const responseData = response?.data
        const userObject = responseData?.userData;
        if(userObject){
        setCurrentUser(userObject);
         // Remove email and domain from localStorage
         localStorage.removeItem('email');
         localStorage.removeItem('domain')
         // Navigate to dashboard
         navigate('/app/dashboard', { replace: true });
        }else{
          setErrors({
            ...errors,
            otp: responseData.message || 'OTP entered is wrong',
          });
        }
       
      } else {
        setErrors({
          ...errors,
          otp: 'OTP entered is wrong',
        });
      }
    } catch (error) {
      console.error('Error handling OTP verification:', error);
      // Handle API call error
      setErrors({
        ...errors,
        otp: 'OTP entered is wrong',
      });
    }
  };

  const isSubmitDisabled = Boolean(errors.otp) || values.otp === '';

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.form}>
        <Typography color="textPrimary" variant="h2">
          Verify OTP
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="body2">
          Please enter the OTP sent to your registered EMAIL ID.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            error={Boolean(errors.otp)}
            fullWidth
            helperText={errors.otp}
            label="Enter OTP"
            margin="normal"
            name="otp"
            onChange={handleChange}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            value={values.otp}
            variant="outlined"
          />

          <Box mt={2}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={isSubmitDisabled}
            >
              Verify OTP
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default OTPForm;
