import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import { green } from '@material-ui/core/colors';
import axios from '../../../utils/axios';

import { servicePath } from '../../../constants/index';

import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { withStyles } from '@material-ui/core/styles';

const dot = require('dot-object');

const { log } = console;

const useStyles = makeStyles(theme => ({
  formControl: {
    fullWidth: true,
    // margin: theme.spacing(1),
    minWidth: 120,
    display: 'flex',
    wrap: 'nowrap'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500]
    },
    '&$checked + $track': {
      backgroundColor: green[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

const MetaInfo = ({ className, ruleMeta, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [openSnack, setOpenSnack] = useState(false);

  const jsonRuleMeta = JSON.stringify(ruleMeta);

  useEffect(() => {
    console.log('Setting again @useEffect');

    setValues({ ...ruleMeta });
  }, [jsonRuleMeta, ruleMeta]);

  const handleChange = event => {
    console.log(event.target, '@handleChange');
    console.log(values, '@handleChange - Values');

    if (event.target.type == 'checkbox') {
      setValues({
        ...values,
        [event.target.name]: event.target.checked
      });
    } else {
      // if (event.target.type == "number") {
      //   event.target.value = parseInt(event.target.value, 10);
      // }
      let row = { [event.target.name]: event.target.value };
      console.log(row, '@@row@@');
      row = dot.object(row);
      const key = Object.keys(row)[0];
      if (typeof row[key] === 'object') {
        row[key] = { ...values[key], ...row[key] };
      }
      setValues({
        ...values,
        ...row
      });
    }
  };

  const saveRuleInfo = async () => {
    console.log('Save Rule Info');
    const inpReq = values;
    for (const v of ['AED', 'KWD', 'SAR', 'QAR', 'BHD']) {
      inpReq.CreditValReferee[v] = parseFloat(inpReq.CreditValReferee[v]);
      if (isNaN(inpReq.CreditValReferee[v])) {
        inpReq.CreditValReferee[v] = 0;
      }
      inpReq.VideoValReferee[v] = parseFloat(inpReq.VideoValReferee[v]);
      if (isNaN(inpReq.VideoValReferee[v])) {
        inpReq.VideoValReferee[v] = 0;
      }
      inpReq.CreditValReferrer[v] = parseFloat(inpReq.CreditValReferrer[v]);
      if (isNaN(inpReq.CreditValReferrer[v])) {
        inpReq.CreditValReferrer[v] = 0;
      }
      inpReq.VideoValReferrer[v] = parseFloat(inpReq.VideoValReferrer[v]);
      if (isNaN(inpReq.VideoValReferrer[v])) {
        inpReq.VideoValReferrer[v] = 0;
      }
      inpReq.CoinValReferee[v] = parseFloat(inpReq.CoinValReferee[v]);
      if (isNaN(inpReq.CoinValReferee[v])) {
        inpReq.CoinValReferee[v] = 0;
      }
      inpReq.CoinVideoValReferee[v] = parseFloat(inpReq.CoinVideoValReferee[v]);
      if (isNaN(inpReq.CoinVideoValReferee[v])) {
        inpReq.CoinVideoValReferee[v] = 0;
      }
      inpReq.CoinValReferrer[v] = parseFloat(inpReq.CoinValReferrer[v]);
      if (isNaN(inpReq.CoinValReferrer[v])) {
        inpReq.CoinValReferrer[v] = 0;
      }
      inpReq.CoinVideoValReferrer[v] = parseFloat(inpReq.CoinVideoValReferrer[v]);
      if (isNaN(inpReq.CoinVideoValReferrer[v])) {
        inpReq.CoinVideoValReferrer[v] = 0;
      }
    }
    inpReq['ruleId'] = ruleMeta.ID
    const result = await axios.post(
      `${servicePath}/saveRuleInfo`,
      inpReq
    );
    if (result.status) {
      setOpenSnack(true);
    }
  };

  if (!ruleMeta) {
    return <p>No Rule info.</p>;
  }

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Snackbar
        autoHideDuration={2000}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
        closeFunc={() => {
          setOpenSnack(false);
        }}
        message="Update successful."
      >
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}
          severity="success"
        >
          <Box style={{ fontWeight: 'bold' }}> Update successful</Box>
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader title="Edit Rule" subheader={`${ruleMeta.Description}`} />
        {/* <Divider/> */}
        <Grid className={classes.item} item style={{ padding: '0 20px' }}>
          {' '}
          <TextareaAutosize
            style={{
              fontSize: '16px',
              fontFamily: 'Helvetica',
              fontWeight: '500',
              padding: '10px',
              marginBottom: '25px',
              marginTop: '0px'
            }}
            rowsMin={1}
            placeholder="Untitled rule description"
            fullWidth
            // helperText="Rule Untitled Description"
            label="Rule Untitled Description"
            name="Description"
            onChange={handleChange}
            required
            value={values.Description}
            variant="outlined"
          />
        </Grid>
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item md={12} sm={12} xs={12}>
              <Typography color="textPrimary" gutterBottom variant="h6">
                Status
              </Typography>

              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={values.IsDeleted || false}
                      onChange={handleChange}
                      name="IsDeleted"
                    />
                  }
                  label="Is Deleted"
                />
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: '300', fontSize: '12px' }}
                  variant="h6"
                >
                  * To completely remove from any usage.
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={values.IsVisible || false}
                      onChange={handleChange}
                      name="IsVisible"
                    />
                  }
                  label="Is Visible"
                />
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: '300', fontSize: '12px' }}
                  variant="h6"
                >
                  * Mark a rule for only Display purpose. Please make sure only
                  one rule is true for Display
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={values.IsValid || false}
                      onChange={handleChange}
                      name="IsValid"
                    />
                  }
                  label="Is Valid"
                />
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: '300', fontSize: '12px' }}
                  variant="h6"
                >
                  * If true, this rule will be used for calculations and giving
                  Credits.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid className={classes.item} item md={4} sm={6} xs={12}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h6"
              style={{ marginBottom: '20px' }}
            >
              Earning Details
            </Typography>
          </Grid>
          <div container wrap="wrap">
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h6"
              style={{ marginBottom: '20px' }}
            >
              Referee(Store Credits)
            </Typography>

            <div
              item
              style={{ display: 'flex', padding: '10px', margin: '20px 0' }}
            >
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in SAR)"
                name="CreditValReferee.SAR"
                onChange={handleChange}
                required
                value={values.CreditValReferee?.SAR}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in AED)"
                name="CreditValReferee.AED"
                onChange={handleChange}
                required
                value={values.CreditValReferee?.AED}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in KWD)"
                name="CreditValReferee.KWD"
                onChange={handleChange}
                required
                value={values.CreditValReferee?.KWD}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in QAR)"
                name="CreditValReferee.QAR"
                onChange={handleChange}
                required
                value={values.CreditValReferee?.QAR}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in BHD)"
                name="CreditValReferee.BHD"
                onChange={handleChange}
                required
                value={values.CreditValReferee?.BHD}
                variant="outlined"
              />
            </div>
          </div>
          <div container wrap="wrap" style={{ margin: '20px 0' }}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h6"
              style={{ marginBottom: '20px' }}
            >
              Referrer(Store Credits)
            </Typography>
            <div
              item
              style={{ display: 'flex', padding: '10px', margin: '20px 0' }}
            >
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in SAR)"
                name="CreditValReferrer.SAR"
                onChange={handleChange}
                required
                value={values.CreditValReferrer?.SAR}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in AED)"
                name="CreditValReferrer.AED"
                onChange={handleChange}
                required
                value={values.CreditValReferrer?.AED}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in KWD)"
                name="CreditValReferrer.KWD"
                onChange={handleChange}
                required
                value={values.CreditValReferrer?.KWD}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in QAR)"
                name="CreditValReferrer.QAR"
                onChange={handleChange}
                required
                value={values.CreditValReferrer?.QAR}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in BHD)"
                name="CreditValReferrer.BHD"
                onChange={handleChange}
                required
                value={values.CreditValReferrer?.BHD}
                variant="outlined"
              />
            </div>
          </div>

          <hr style={{ marginBottom: '20px' }} />
          
          <div container wrap="wrap">
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h6"
              style={{ marginBottom: '20px' }}
            >
              Referee(Styli Coin)
            </Typography>

            <div
              item
              style={{ display: 'flex', padding: '10px', margin: '20px 0' }}
            >
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in SAR)"
                name="CoinValReferee.SAR"
                onChange={handleChange}
                required
                value={values.CoinValReferee?.SAR}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in AED)"
                name="CoinValReferee.AED"
                onChange={handleChange}
                required
                value={values.CoinValReferee?.AED}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in KWD)"
                name="CoinValReferee.KWD"
                onChange={handleChange}
                required
                value={values.CoinValReferee?.KWD}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in QAR)"
                name="CoinValReferee.QAR"
                onChange={handleChange}
                required
                value={values.CoinValReferee?.QAR}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in BHD)"
                name="CoinValReferee.BHD"
                onChange={handleChange}
                required
                value={values.CoinValReferee?.BHD}
                variant="outlined"
              />
            </div>
          </div>
          <div container wrap="wrap" style={{ margin: '20px 0' }}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h6"
              style={{ marginBottom: '20px' }}
            >
              Referrer(Styli Coin)
            </Typography>
            <div
              item
              style={{ display: 'flex', padding: '10px', margin: '20px 0' }}
            >
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in SAR)"
                name="CoinValReferrer.SAR"
                onChange={handleChange}
                required
                value={values.CoinValReferrer?.SAR}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in AED)"
                name="CoinValReferrer.AED"
                onChange={handleChange}
                required
                value={values.CoinValReferrer?.AED}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in KWD)"
                name="CoinValReferrer.KWD"
                onChange={handleChange}
                required
                value={values.CoinValReferrer?.KWD}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in QAR)"
                name="CoinValReferrer.QAR"
                onChange={handleChange}
                required
                value={values.CoinValReferrer?.QAR}
                variant="outlined"
              />
              <TextField
                style={{ padding: '5px' }}
                fullWidth
                label="Credit (in BHD)"
                name="CoinValReferrer.BHD"
                onChange={handleChange}
                required
                value={values.CoinValReferrer?.BHD}
                variant="outlined"
              />
            </div>
          </div>


          {values.IsVisible ? (
            <>
              <div container wrap="wrap" style={{ margin: '20px 0' }}>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                  style={{ marginBottom: '20px' }}
                >
                  Video Referee(Store Credits)
                </Typography>

                <div
                  item
                  style={{ display: 'flex', padding: '10px', margin: '20px 0' }}
                >
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in SAR)"
                    name="VideoValReferee.SAR"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferee?.SAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in AED)"
                    name="VideoValReferee.AED"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferee?.AED}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in KWD)"
                    name="VideoValReferee.KWD"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferee?.KWD}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in QAR)"
                    name="VideoValReferee.QAR"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferee?.QAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in BHD)"
                    name="VideoValReferee.BHD"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferee?.BHD}
                    variant="outlined"
                  />
                </div>
              </div>
              <div container wrap="wrap" style={{ margin: '20px 0' }}>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                  style={{ marginBottom: '20px' }}
                >
                  Video Referrer (Store Credits)
                </Typography>
                <div
                  item
                  style={{ display: 'flex', padding: '10px', margin: '20px 0' }}
                >
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in SAR)"
                    name="VideoValReferrer.SAR"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferrer?.SAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in AED)"
                    name="VideoValReferrer.AED"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferrer?.AED}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in KWD)"
                    name="VideoValReferrer.KWD"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferrer?.KWD}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in QAR)"
                    name="VideoValReferrer.QAR"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferrer?.QAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in BHD)"
                    name="VideoValReferrer.BHD"
                    onChange={handleChange}
                    required
                    value={values.VideoValReferrer?.BHD}
                    variant="outlined"
                  />
                </div>
              </div>


              <hr style={{ marginBottom: '20px' }} />
              <div container wrap="wrap" style={{ margin: '20px 0' }}>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                  style={{ marginBottom: '20px' }}
                >
                  Video Referee(Styli Coin)
                </Typography>

                <div
                  item
                  style={{ display: 'flex', padding: '10px', margin: '20px 0' }}
                >
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in SAR)"
                    name="CoinVideoValReferee.SAR"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferee?.SAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in AED)"
                    name="CoinVideoValReferee.AED"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferee?.AED}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in KWD)"
                    name="CoinVideoValReferee.KWD"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferee?.KWD}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in QAR)"
                    name="CoinVideoValReferee.QAR"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferee?.QAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in BHD)"
                    name="CoinVideoValReferee.BHD"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferee?.BHD}
                    variant="outlined"
                  />
                </div>
              </div>
              <div container wrap="wrap" style={{ margin: '20px 0' }}>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                  style={{ marginBottom: '20px' }}
                >
                  Video Referrer (Styli Coin)
                </Typography>
                <div
                  item
                  style={{ display: 'flex', padding: '10px', margin: '20px 0' }}
                >
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in SAR)"
                    name="CoinVideoValReferrer.SAR"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferrer?.SAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in AED)"
                    name="CoinVideoValReferrer.AED"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferrer?.AED}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in KWD)"
                    name="CoinVideoValReferrer.KWD"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferrer?.KWD}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in QAR)"
                    name="CoinVideoValReferrer.QAR"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferrer?.QAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{ padding: '5px' }}
                    fullWidth
                    label="Credit (in BHD)"
                    name="CoinVideoValReferrer.BHD"
                    onChange={handleChange}
                    required
                    value={values.CoinVideoValReferrer?.BHD}
                    variant="outlined"
                  />
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </CardContent>
        <Divider />
        {values?.IsVisible && (
          <>
            <CardContent>
              <Grid spacing={6} wrap="wrap">
                <Grid className={classes.item} item>
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    Terms URL
                  </Typography>

                  <TextField
                    style={{ padding: '10px', margin: '20px 0' }}
                    fullWidth
                    label="Term & Conditions Url"
                    name="TermsUrl"
                    onChange={handleChange}
                    required
                    value={values.TermsUrl}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
          </>
        )}

        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={saveRuleInfo}>
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default MetaInfo;
