import React, {useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import {Formik} from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';

import Page from 'src/components/Page';
import {authServicePath,servicePath} from "../../constants/index";
import {setCurrentUser} from '../../utils/helper';
const domain = new URL(servicePath).hostname;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  ulStyle: {
    color: colors.red[600],
    paddingLeft: theme.spacing(3)
  }
}));
const regEx = /^(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const loginWithEmailPasswordAsync = async (email, password,domain) => {
  // console.log('AUTH_SERVICE_URL:::', authServicePath)
  const result = await axios.post(
    `${authServicePath}/login`,
    {
      email,
      password,
      domain
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  console.log('result:::', result.data);
  return result.data;
};

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [fbMsg, setFbMessage]= useState("");
  const [passwordError, setPasswordError] = useState(false);
  const validatePassword = (email,password) => {
    if (!regEx.test(password) || (email == password)) {
      setPasswordError(true);
      setFbMessage("");
      return true;
    }else{
      setPasswordError(false);
      return false;
    }
  };
  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values) => {
              //TODO: validation. Or basic password check.
              const status = validatePassword(values.email, values.password);
              if(!status){
                try{
              
              const loginUser = await loginWithEmailPasswordAsync(values.email, values.password,domain);
              if (loginUser.status) {
                  const verifyOtpStatus = loginUser?.data?.message;
                  const item = {
                    uuid: loginUser?.data?.uuid,
                    name: loginUser?.data?.displayName,
                    email: loginUser?.data?.email || values.email,
                    emailVerified: loginUser?.data?.registered,
                    token: loginUser?.data?.token,
                    refreshToken: loginUser?.data?.refreshToken,
                  }
                  if(!verifyOtpStatus){
                  setCurrentUser(item);
                  navigate('/app/dashboard')
                }else{
                  console.log("message no")
                  localStorage.setItem('email', values.email);
              //  localStorage.setItem('domain', domain);
               setFbMessage("Loggin in...");
               navigate('/verify-otp', {replace: false});
                }
              }else{
                setFbMessage("No user found. Please try a valid login")
              localStorage.removeItem('item');
              }
            }catch(e){
              setFbMessage("No user found. Please try a valid login");
              }
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on IVY
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={3}
                >
                </Grid>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Box>
                  <p> {fbMsg}</p>
                  {passwordError &&
                  <>
                  <span style={{ color: colors.blue[600] }}>Your Password needs to :</span>
                  <ul className={classes.ulStyle}>
                      <li>At least 8 alphanumeric characters</li>
                      <li>minimum one special character</li>
                      <li>Password should not match with email Address</li>
                  </ul>
                  </>
                  }
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
