import React, {useState} from 'react';
import axios from "../../../utils/axios"

import {servicePath} from "../../../constants";
import {getRuleSummary} from "../../../constants/msgSummary"

const SummaryText = () => {
  const [ruleSummaryEn, setRuleSummaryEn] = useState("");
    const [ruleSummaryAr, setRuleSummaryAr] = useState("");
  React.useEffect(() => {
        const fetchData = async () => {
          const result = await axios.get(`${servicePath}/getConfigMessage`);
          result && setRuleSummaryEn(result.data[0]?.sms_text?.en);
          result && setRuleSummaryAr(result.data[0]?.sms_text?.ar);
        };
    
        fetchData();
      }, []);

  const pageTitle = "Message Configuration";
  const cardHeaderTitle= "Sms Text Configuration";
  const cardHeaderSubheader = "Sms text configured from here";
  const isMsgConfig = true

return(
getRuleSummary(pageTitle,cardHeaderTitle,cardHeaderSubheader,isMsgConfig)
);
};

export default SummaryText;

