import React, { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import { Calendar } from 'react-feather';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import { auth } from '../../Firebase';
import FormControl from '@material-ui/core/FormControl';
import { DateRangePicker } from 'materialui-daterange-picker';

import { GlobalFilterCtx } from '../../App';
import Typography from '@material-ui/core/Typography';
import {removeFromCookie} from '../../utils/helper'
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Select,
  Toolbar
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  dateRange: {
    position: 'absolute !important',
    right: '5px',
    top: '-25px',
    button: {
      margin: '20px'
    }
  },
  select: {
    color: 'white',
    border: '2px solid white',
    textShadow: 'none',
    paddingRight: '24px',
    borderRadius: '5px',
    padding: '5px',
    margin: '2px',
    borderStyle: ['solid', 'groove']
  },
  icon: { color: 'white' },
  label: { color: 'white' },
  formControl: {
    borderRadius: '5px',
    color: 'white',
    margin: '5px 5px 0 10px'
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const { store, setStore } = useContext(GlobalFilterCtx);
  const { date, setDate } = useContext(GlobalFilterCtx);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  console.log(date, 'DateRange');
  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '23px',
              color: 'white',
              fontFamily: "'Roboto'",
              fontWeight: 'bold'
            }}
          >
            <Logo />
            <span style={{ padding: '0 10px' }}>
              <span style={{ fontWeight: '300' }}>Styli </span>{' '}
              <span style={{ fontWeight: 'bold' }}>IVY</span>
            </span>
          </Box>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <DateRangePicker
            open={open}
            initialDateRange={date}
            toggle={toggle}
            wrapperClassName={classes.dateRange}
            onChange={range => {
              console.log(
                {
                  startDate: `${moment(range.startDate).format(
                    'YYYY-MM-DD'
                  )}T00:00:00.000Z`,
                  endDate: `${moment(range.endDate).format(
                    'YYYY-MM-DD'
                  )}T00:00:00.000Z`
                },
                '@range@DateRangePicker'
              );
              setDate({
                startDate: `${moment(range.startDate).format(
                  'YYYY-MM-DD'
                )}T00:00:00.000Z`,
                endDate: `${moment(range.endDate).format(
                  'YYYY-MM-DD'
                )}T00:00:00.000Z`
              });
            }}
          />
          {date && (
            <Chip
              label={`[${moment(date.startDate).format(
                'YYYY-MM-DD'
              )} - ${moment(date.endDate).format(
                'YYYY-MM-DD'
              )}] Clear`}
              onDelete={() => setDate(undefined)}
              color="primary"
            />
          )}
          <IconButton color="inherit" onClick={toggle}>
            {open ? (
              <Typography
                color="white"
                style={{ textDecoration: 'underline' }}
                gutterBottom
                variant="h6"
              >
                Apply
              </Typography>
            ) : (
              <Calendar />
            )}
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <FormControl className={classes.formControl}>
            <Select
              native
              value={store}
              onChange={e => {
                setStore(String(e.target.value));
              }}
              label="Store"
              classes={{
                select: classes.select,
                icon: classes.icon
              }}
              inputProps={{
                name: 'Store',
                id: 'outlined-age-native-simple'
              }}
              MenuProps={{ classes: { paper: classes.select } }}
            >
              <option value="all">All</option>
              <option value="sa">SA</option>
              <option value="kw">KW</option>
              <option value="ae">AE</option>
              <option value="qa">QA</option>
              <option value="bh">BH</option>
            </Select>
          </FormControl>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            onClick={async () => {
              await auth
                .signOut()
                .then(user => {
                  console.log('Signing out...');
                  removeFromCookie();
                  navigate('/login', { replace: true });
                })
                .catch(error => console.log(error));
            }}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
