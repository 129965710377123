const getCurrentDomain = () => {
  const subdomain = window.location.hostname;
  const subdomainParts = subdomain.split('.');
  const domainName = subdomainParts.slice(-2).join('.');
  return domainName;
};

export const getCurrentUser = () => {
  let user = null;
  const domainName = getCurrentDomain();
  try {
    user = getJsonCookie('styli_sso_user', domainName);
  } catch (error) {
    console.error('Error getting current user:', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user, expirationDays = 0) => {
  const cookieName = 'styli_sso_user';
  try {
    if (user) {
      setJsonCookie(cookieName, user, expirationDays);
    } 
    else {
      console.log("user not found in the setCurrentUser");
      removeFromCookie();
    }
  } catch (error) {
    console.error('Error setting current user:', error);
  }
};

export const removeFromCookie = () => {
  const cookieName = 'styli_sso_user';
  document.cookie = `${cookieName}=;`;
};

const getJsonCookie = (cookieName, domain) => {
  const cookieValue = getCookie(cookieName, domain);
  if (cookieValue) {
    try {
      return JSON.parse(decodeURIComponent(cookieValue));
    } catch (error) {
      console.error('Error parsing JSON from cookie:', error);
    }
  }
  return null;
};

 const getCookie = (cookieName, domain) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(cookieName + '=') === 0) {
      if (domain && cookie.indexOf('domain=' + domain) === -1) {
        return cookie.substring(cookieName.length + 1);
      }
    }
  }
  return null;
};

  const setJsonCookie = (cookieName, jsonObject, expirationDays) => {
  const jsonString = JSON.stringify(jsonObject);
  let cookieString = `${cookieName}=${encodeURIComponent(jsonString)};`;

  if (expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    cookieString += ` expires=${expirationDate.toUTCString()};`;
  }
  try{
  document.cookie = cookieString;
  return true;
  }catch(e){
    console.log('error',error)
  }
};