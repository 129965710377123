import React from 'react';
import Budget from './Budget';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";


const DashboardSection = ({title="", type="", classes, dashboardValues}) => {
 
  return (
    <>
      <Box>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      </Box>
      <Box
        container
        spacing={3}
        style={{padding: "5px", display: "flex", justifyContent: "space-evenly"}}
      >
        <Budget dashboardValues={dashboardValues} currency="SAR" seg={"Total"} type={type} dname={"TOTAL AMOUNT CREDITED"}/>
        <Budget dashboardValues={dashboardValues} currency="AED" seg={"Total"} type={type} dname={"TOTAL AMOUNT CREDITED"}/>
        <Budget dashboardValues={dashboardValues} currency="KWD" seg={"Total"} type={type} dname={"TOTAL AMOUNT CREDITED"}/>
        <Budget dashboardValues={dashboardValues} currency="QAR" seg={"Total"} type={type} dname={"TOTAL AMOUNT CREDITED"}/>
        <Budget dashboardValues={dashboardValues} currency="BHD" seg={"Total"} type={type} dname={"TOTAL AMOUNT CREDITED"}/>
        <Box></Box>
      </Box>
    </>   
  );
};

export default DashboardSection;
