import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Radio,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {servicePath} from '../../../constants/index';
import axios from "../../../utils/axios"

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Switchs = ({ className, ...rest }) => {
  const classes = useStyles();
  const [rows, setRuleMeta] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(`${servicePath}/settings`, {});
      result && setRuleMeta(result.data.settings_list);
      setIsLoading(false);
    };

    setIsLoading(true);
    fetchData();

  }, []);

  const saveSettings = async () => {
    setIsLoading(true);
    await axios.post(`${servicePath}/saveSettings`, rows);
    setIsLoading(false);
  };

  const handelUpdate = (index) => {
    const newRows = [...rows];
    console.log("newRows:::","index");
    newRows[index].Value = !rows[index].Value;
    setRuleMeta(newRows);
  }

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
        {isLoading && <Box style={{position: "absolute", top: "50%", left: "50%"}}><CircularProgress/></Box>}
      <Card>
        <CardHeader
          subheader="Manage the settings"
          title="Settings"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              {rows
                ?.map((row, index) => {

                  return (
                    <>
              {/* <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Enable Styli Coin
              </Typography> */}
              <Button
                color="primary"
                variant="contained"
                onClick={()=>handelUpdate(index)}
              >
               {row.Value ? "Disable Styli Coin" : "Enable Styli Coin"} 
              </Button>
              </>
                  )})
                }
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={saveSettings}
          >
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Switchs.propTypes = {
  className: PropTypes.string
};

export default Switchs;
