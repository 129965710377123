import React, {useEffect, useState} from 'react';
import {Box, Container, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import {Graph} from "react-d3-graph";
import {servicePath} from "../../constants";
import axios from "../../utils/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));
const data = {
  nodes: [{id: "Harry"}, {id: "Sally"}, {id: "Alice"}],
  links: [
    {source: "Harry", target: "Sally"},
    {source: "Harry", target: "Alice"},
  ],
};

// the graph configuration, just override the ones you need
const myConfig = {
  "automaticRearrangeAfterDropNode": true,
  "directed": true,
  "focusAnimationDuration": 0.75,
  "focusZoom": 1,
  "freezeAllDragEvents": false,
  "height": 960,
  "highlightDegree": 2,
  "highlightOpacity": 0.2,
  "linkHighlightBehavior": true,
  "maxZoom": 12,
  "minZoom": 0.05,
  "nodeHighlightBehavior": true,
  "panAndZoom": true,
  "staticGraph": false,
  "staticGraphWithDragAndDrop": false,
  "width": 1200,
  "d3": {
    "alphaTarget": 0.05,
    "gravity": -250,
    "linkLength": 120,
    "linkStrength": 2,
    "disableLinkForce": false
  },
  "node": {
    "color": "#fc7419",
    "fontColor": "#635c5c",
    "fontSize": 12,
    "fontFamily": '"Roboto", "Helvetica", "Arial", sans-serif',
    "fontWeight": "normal",
    "strokeColor": "none",
    "strokeWidth": 4,
    "svg": "",
    "symbolType": "circle",
    "highlightColor": "#fc7419",
    "highlightFontSize": 14,
    "highlightFontWeight": "bold",
    "highlightStrokeColor": "#fc7419",
    "highlightStrokeWidth": 1.5,
    "opacity": 0.9,
    "renderLabel": true,
    "labelProperty": "name",
    "size": 1200,
  },
  "link": {
    "color": "#9c9594",
    "fontColor": "#635c5c",
    "fontSize": 8,
    "fontWeight": "normal",
    "highlightColor": "#508250e3",
    "highlightFontSize": 8,
    "highlightFontWeight": "normal",
    "labelProperty": "name",
    "mouseCursor": "pointer",
    "opacity": 1,
    "renderLabel": true,
    "semanticStrokeWidth": true,
    "strokeWidth": 1,
    "markerHeight": 5,
    "markerWidth": 5,
    "strokeDasharray": 0,
    "strokeDashoffset": 0,
    "strokeLinecap": "butt"
  }
}

const GraphView = () => {
  const classes = useStyles();
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${servicePath}/getGraph/`);
      console.log(result, "@@@result@@@")
      if (result && result.data) {
        const meta = result.data.meta;
        setNodes(result.data.nodes.map((v) => {
          return meta[v]
        }))
        setLinks(result.data.links.map((v) => {
          const key = Object.keys(v)[0];
          const value = v[key];
          return {source: key, target: value}
        }))
      }
    };

    fetchData();
  }, []);


  const onClickNode = (nodeId) => {
    // window.alert(`Clicked node ${nodeId}`);
  };

  const onClickLink = (source, target) => {
    // window.alert(`Clicked link between ${source} and ${target}`);
  };

  return (
    <Page
      className={classes.root}
      title="Connections Graph"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          {nodes?.length && links?.length ? <Graph
            id="graph-id" // id is mandatory
            data={{nodes: [...nodes], links: [...links]}}
            config={myConfig}
            onClickNode={onClickNode}
            onClickLink={onClickLink}
          /> : ""}
        </Box>
      </Container>
    </Page>
  );
};

export default GraphView;
