import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  capitalize: {textTransform: "capitalize"},
  tableRow: {
    cursor: "pointer",
  }
}));

const Results = ({className, rules, ...rest}) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = rules.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const getBenefitTxt = (rule) => {
    let txt = rule.CreditAmount ? rule.CreditAmount + " (Credits)" : undefined;
    if (!txt) {
      txt = rule.DiscountPercentage ? rule.DiscountPercentage + " (Discount %)" : "";
    }
    return txt
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (ruleId) => {
    const newWindow = window.open(`/app/edit/${ruleId}`, 'self', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === rules.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < rules.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  Display Only?
                </TableCell>
                <TableCell>
                  Action
                </TableCell>
                <TableCell>
                  Is Valid?
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rules.map((rule) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={rule.ID}
                  //onClick={() => handleClick(rule.ID)}
                  style={rule.IsVisible?  {backgroundColor: "rgb(253 239 198)"}: {}}
                  selected={selectedCustomerIds.indexOf(rule.ID) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(rule.ID) !== -1}
                      onChange={(event) => handleSelectOne(event, rule.ID)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                      className={classes.capitalize}
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        onClick={() => handleClick(rule.ID)}
                      >
                        {rule.Type}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                  onClick={() => handleClick(rule.ID)}
                  >
                    {`${rule.Description}`}
                  </TableCell>
                  <TableCell>
                    {`${rule.IsVisible ? 'True' : "False"}`}
                  </TableCell>

                  <TableCell
                  onClick={() => handleClick(rule.ID)}
                  >
                    Edit
                  </TableCell>
                  <TableCell>
                    <span style={rule.IsValid? {color: "green", fontWeight: "bold",textTransform:"capitalize"}: {color: "#ec6363", fontWeight: "bold", textTransform:"capitalize"}}> {`${rule.IsValid} `}</span>

                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rules.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  rules: PropTypes.array.isRequired
};

export default Results;
