// const firebaseConfigQA = {
//   apiKey: 'AIzaSyCM8p8y22CgYwMdjiKLFfl3lWQ3cf_N1H8',
//   authDomain: 'supply-qa.firebaseapp.com',
//   projectId: 'supply-qa',
//   storageBucket: 'supply-qa.appspot.com',
//   messagingSenderId: '428145837378',
//   appId: '1:428145837378:web:eeeccba92b0495eec2f4db'
// };
const firebaseConfigQA = {
  apiKey: 'AIzaSyCM8p8y22CgYwMdjiKLFfl3lWQ3cf_N1H8',
  authDomain: 'supply-qa.firebaseapp.com',
  projectId: 'supply-qa',
  storageBucket: 'supply-qa.appspot.com',
  messagingSenderId: '428145837378',
  appId: '1:428145837378:web:eeeccba92b0495eec2f4db'
};
const firebaseConfigDev = {
  apiKey: 'AIzaSyCADXDR33XJC3gCS3iaFLWytXo2m4aB_OM',
  authDomain: 'searchvm-dev.firebaseapp.com',
  databaseURL: 'https://searchvm-dev.firebaseio.com',
  projectId: 'searchvm-dev',
  storageBucket: 'searchvm-dev.appspot.com',
  messagingSenderId: '679987494918',
  appId: '1:679987494918:web:79f0c7ed8b4afc2f368387'
};
const firebaseConfigProd = {
  apiKey: 'AIzaSyAYZumsppN9Yu-a7vSLlMrP65dlAj2R-Rg',
  authDomain: 'supplyapps-prod.firebaseapp.com',
  databaseURL: 'https://supplyapps-prod.firebaseio.com',
  projectId: 'supplyapps-prod',
  storageBucket: 'supplyapps-prod.appspot.com',
  messagingSenderId: '945566594699',
  appId: '1:945566594699:web:1cff3c482c9b69d684c68e'
};

export const firebaseConfig =
  process.env.NODE_ENV === 'development'
    ? firebaseConfigDev
    : process.env.NODE_ENV === 'production'
    ? firebaseConfigProd
    : firebaseConfigQA;

export const adminRoot = '/app';
export const isAuthGuardActive = true;
