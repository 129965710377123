import React, {useState} from 'react';
import axios from "../../../utils/axios"

import {servicePath} from "../../../constants";
import {getRuleSummary} from "../../../constants/msgSummary"

const SummaryText = () => {
  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(`${servicePath}/getRuleSummary`, {});
      result && setRuleSummaryEn(result.data.SummaryTextEn);
      result && setRuleSummaryAr(result.data.SummaryTextAr);
    };

    fetchData();
  }, []);

  const pageTitle = "Edit Referral Rule";
  const cardHeaderTitle= "Rule Summary";
  const cardHeaderSubheader = "Content Summary shown on the Referral Screen";
  const isMsgConfig = false

return(
getRuleSummary(pageTitle,cardHeaderTitle,cardHeaderSubheader,isMsgConfig)
);
};

export default SummaryText;
