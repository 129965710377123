import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import SearchIcon from "@material-ui/icons/Search";

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "../../utils/axios"
import {servicePath} from '../../constants/index';
import {GlobalFilterCtx} from "../../App";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {storeMapper} from "../edit/CreditHistory/ReactMatTable";
import {useStyles} from '../../constants/reactctMatTableStyle';
import {useToolbarStyles} from '../../constants/reactMatTableToolbarStyle';

const headCells = [
  {id: 'userId', numeric: false, disablePadding: true, label: 'User Id'},
  {id: 'userEmail', numeric: true, disablePadding: false, label: 'User Email'},
  {id: 'store', numeric: true, disablePadding: false, label: 'Store'},
  {id: 'smsSent', numeric: true, disablePadding: false, label: 'SMS Sent'},
  {id: 'appInstalls', numeric: true, disablePadding: false, label: 'App Installs'},
  {id: 'totalSignups', numeric: true, disablePadding: false, label: 'Total Signups'},
  {id: 'storeCredit', numeric: true, disablePadding: false, label: 'Store Credit (Earned)'},
  {id: 'styliCoin', numeric: true, disablePadding: false, label: 'Styli Coin (Earned)'},
  {id: 'creditBalance', numeric: true, disablePadding: false, hide: false, label: 'Credit Balance (Magento)'},
  {id: 'coinBalance', numeric: true, disablePadding: false, hide: false, label: 'Total StyliCoin Balance'},
];

function EnhancedTableHead(props) {
  const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hide) {
            return ""
          }
          return <TableCell
            key={headCell.id}
            colSpan={1}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const deleteSelected = async (selected) => {
  console.log(selected, "Delete Selected");
  alert('Disabled Currently');
}

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const [query, setQuery] = React.useState("");
  const {numSelected, selected, searchCredit} = props;

  const syncBQ = async() => {
    alert("Sync has begun. This should take appx 5 mins.")
    await axios.get(`${servicePath}/syncLeaderBoard`);
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            LeaderBoard
          </Typography>

          <Box style={{display: "flex", justifyContent: "space-around", alignItems: "baseline"}}>
            <TextField
              label="Search by Email "
              style={{margin: "0 20px", width: "200px"}}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  searchCredit(query);
                }
              }}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={() => searchCredit(query)}>
                      <SearchIcon/>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              color="primary"
              style={{width: "100px"}}
              variant="contained"
              disabled={true}
              onClick={syncBQ}
            >
              Sync BQ
            </Button>
          </Box>
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon onClick={() => deleteSelected(selected)}/>
          </IconButton>
        </Tooltip>
      ) : (
       <Box style={{padding:"5px"}}></Box>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRuleMeta] = React.useState([]);
  const [creditBalance, setCreditBalance] = React.useState([]);
  const [coinBalance, setCoinBalance] = React.useState([]);
  const {store, date} = useContext(GlobalFilterCtx);
  const [pQuery, setPQuery] = React.useState("");

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(`${servicePath}/leaderBoard`, {
        from: '' + page, size: '' + rowsPerPage, query: pQuery, sort: orderBy, order: order
      });
      result && setRuleMeta(result.data.rows);
      result && setCreditBalance(result.data.storeCreditBalance);
      result && setCoinBalance(result.data.earnCoinBalance);
      result && setTotalCount(result.data?.count || result.data.rows?.length);
      setIsLoading(false);
    };

    setIsLoading(true);
    fetchData();

  }, [page, rowsPerPage, store, date, pQuery, orderBy, order]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const searchCredit = (query) => {
    setPQuery(query);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.ID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} selected={selected} searchCredit={searchCredit}/>
        {isLoading && <Box style={{position: "absolute", top: "50%", left: "50%"}}><CircularProgress/></Box>}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'} 
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {rows
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.ID);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.ID)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ID}
                      selected={isItemSelected}
                    >
                      {headCells.map((v) => {
                        if(v.id === 'store'){
                          return <TableCell style={{textTransform: "uppercase"}}>{storeMapper[row[v.id]]} {v.hide ? v.label : ""}</TableCell>
                        }
                        if(v.id === 'creditBalance'){
                          {console.log(creditBalance, '@creditbalance', row.userId)}
                          return <TableCell style={{textTransform: "uppercase"}}>{creditBalance[parseInt(row.userId)] || "0"}</TableCell>
                        }
                        if(v.id === 'coinBalance'){
                          {console.log(coinBalance, '@coinBalance', row.userId)}
                          return <TableCell style={{textTransform: "uppercase"}}>{coinBalance[parseInt(row.userId)] || "0"}</TableCell>
                        }
                        return <TableCell>{row[v.id]} {v.hide ? v.label : ""}</TableCell>
                      })} 
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
