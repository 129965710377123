import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, Button, Card, CardContent, InputAdornment, makeStyles, SvgIcon, TextField} from '@material-ui/core';
import {Search as SearchIcon} from 'react-feather';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from "@material-ui/lab/Alert";
import { servicePath } from '../../../constants/index';
import axios from "../../../utils/axios"
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    color: "#546e7a",
    fontFamily: "'Roboto'",
    fontWeight: 500,
    fontSize: "20px",
  },
}));


const Toolbar = ({className, filterRules, ...rest}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const addRule = async () => {
    alert("Creating new rule");
    //Create new rule
    const newRuleRes = await axios.get(`${servicePath}/createRule`);
    const {data: newRule} = newRuleRes
    const ruleId = newRule && newRule.InsertedID;
    if (ruleId) {
        window.open(`/app/edit/${ruleId}`, "_self")
      // if (newWindow) newWindow.opener = null
    } else {
      setOpen(true)
    }

  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">Error creating new rule!</Alert>
      </Snackbar>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Referral Rules
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={addRule}
        >
          Add rule
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                onChange={filterRules}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon/>
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Rule"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
