import React from 'react';
import clsx from 'clsx';
import {Card, colors, Grid, makeStyles, Typography} from '@material-ui/core';
import Box from "@material-ui/core/Box";
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import Avatar from "@material-ui/core/Avatar";
import {
  DollarSign as DollarSign,
  Hash as Hash,
  MessageSquare as MessageSquare,
  Tablet,
  Users as UsersIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.blueGrey[600],
    height: 56,
    width: 56,
    marginTop: "10px"
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const Budget = ({className, dashboardValues, currency, dname, seg, type, ...rest}) => {
  const classes = useStyles();

  const formatNo = (num) => {
    return Math.round(num * 100) / 100  ;
  }
  const getIcons = () => {
    if (seg) {
      return <DollarSign/>;
    } else {
      if (type == "TotalSMS") {
        return <MessageSquare/>
      }
      if (type == "TotalRules") {
        return <Hash/>
      }
      if (type == "TotalReferrers") {
        return <UsersIcon/>
      }
      if (type == "TotalAppInstalls") {
        return <Tablet/>
      }
      return <InsertChartIcon/>
    }
  }
  return (
    <Card
      className={clsx(classes.root, className)}
      style={{
        height: seg ? "250px" : "166px",
        width: "20%", margin: "10px"
      }}
      {...rest}
    >
      <div style={{height: "100%"}}>
        <div
          container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "100%",
            padding: "10px"
          }}
          justify="space-between"

          spacing={3}
        >
          <Grid item>
            <Avatar className={classes.avatar} style={{backgroundColor:type === "TotalCoinByStore" ? "#ffc623":""}}>
              {getIcons()}
            </Avatar>
          </Grid>
          <Grid item style={{padding: "25px 0 0 "}}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
              style={{display: "flex", flex: "1", textAlign: "center"}}
            >
              {dname}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
              style={{display: "flex", justifyContent: "center"}}
            >
              {type == "TotalCreditByStore" || type === "TotalCoinByStore" ? currency + " " + (formatNo(dashboardValues?.[type]?.[seg]?.[currency] || 0) ) :
                dashboardValues && dashboardValues[type]}
            </Typography>
          </Grid>
          {type == "TotalCreditByStore" || type === "TotalCoinByStore" ?
            <Box item style={{padding: "25px 0 0 "}} style={{display: "flex", justifyContent: "space-between"}}>
              <Box style={{margin: "10px"}}>
                <Box style={{display: "flex", flexDirection: "column"}}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    style={{display: "flex", flex: "1"}}
                  >Referrer</Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    style={{display: "flex", flex: "1"}}
                  >{currency + " "}{formatNo(dashboardValues?.[type]?.["Referrer"]?.[currency] || 0)} </Typography>
                </Box>
              </Box>
              <Box style={{margin: "10px"}}>
                <Box style={{display: "flex", flexDirection: "column"}}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    style={{display: "flex", flex: "1"}}
                  > Referee </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    style={{display: "flex", flex: "1"}}
                  >{currency + " "}{formatNo(dashboardValues?.[type]?.["Referee"]?.[currency] || 0)} </Typography>
                </Box>
              </Box>
            </Box>
            : ""}
        </div>
      </div>
    </Card>
  );
};


export default Budget;
