
import React, {useState} from 'react';
import {
    Card,
    CardHeader,
    Box,
    Button,
    Container,
    Divider,Snackbar,
    Grid,
    Paper,
    TextareaAutosize
  } from '@material-ui/core';
  import Alert from "@material-ui/lab/Alert";
  import Page from 'src/components/Page';
  import axios from "../utils/axios"
  import {useStyles} from './style';
  import {servicePath} from "../constants";
  import Typography from "@material-ui/core/Typography";
  
export const getRuleSummary = (pageTitle,cardHeaderTitle,cardHeaderSubheader,isMsgConfig) => 
{
  const classes = useStyles();
  const [ruleSummaryEn, setRuleSummaryEn] = useState("");
  const [ruleSummaryAr, setRuleSummaryAr] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  let apiURL = `${servicePath}/configMessage`;
  let reqParam = {"key":"sms_text", "value": {"ar":ruleSummaryAr, "en": ruleSummaryEn}};
  if(isMsgConfig===false){
    apiURL = `${servicePath}/saveRuleSummary`;
    reqParam = {summaryTextEn: ruleSummaryEn, summaryTextAr: ruleSummaryAr};
  }

  const saveRuleSummary = async () => {
    const result = await axios.post(apiURL, reqParam);
    if (result.status || result.data.status) {
      setOpenSnack(true)
    }
  }

  return (
    <Page
      className={classes.root}
      title={pageTitle}
    >
      <Snackbar
        autoHideDuration={2000}
        open={openSnack}
        onClose={() =>  setOpenSnack(false)}
        closeFunc={() => {
          setOpenSnack(false)
        }}
        message="Update successful.">
        <Alert onClose={() => {
          setOpenSnack(false)
        }} severity="success">
          <Box style={{fontWeight: "bold"}}> Update successful</Box>
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <Card style={{marginBottom: '20px'}}>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Card>
                <CardHeader
                  title={cardHeaderTitle}
                  subheader={cardHeaderSubheader}
                />
                <Divider/>
                <Grid
                  className={classes.item}
                  item
                  style={{padding: "20px"}}
                >
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h6"
                  >
                    English
                  </Typography>
                  <TextareaAutosize
                    style={{
                      fontSize: "16px",
                      fontFamily: "Helvetica",
                      fontWeight: "500",
                      width: "100%",
                      padding: "10px",
                      marginTop: "0px"
                    }}
                    rowsMin={3}
                    placeholder="Refer your friends and get SAR 10 on every friend who installs the app."
                    fullWidth
                    helperText="Refer and Earn"
                    label="Rule Summary Description"
                    name="ruleSummaryEn"
                    onChange={(e) => {
                      setRuleSummaryEn(e.target.value)
                    }}
                    required
                    value={ruleSummaryEn}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  className={classes.item}
                  item
                  style={{padding: "0 20px"}}
                >
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h6"
                  >
                    Arabic
                  </Typography>
                  <TextareaAutosize
                    style={{
                      fontSize: "16px",
                      fontFamily: "Helvetica",
                      fontWeight: "500",
                      width: "100%",
                      padding: "10px",
                      marginBottom: "25px",
                      marginTop: "0px"
                    }}
                    rowsMin={3}
                    placeholder="شسيبشنس  شسيب  شسبش يوبي."
                    fullWidth
                    helperText="Refer and Earn"
                    label="Rule Summary Description"
                    name="ruleSummaryAr"
                    onChange={(e) => {
                      console.log(e.target.value, "Summary text value");
                      setRuleSummaryAr(e.target.value)
                    }}
                    required
                    value={ruleSummaryAr}
                    variant="outlined"
                  />
                </Grid>
                <Divider/>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={saveRuleSummary}
                  >
                    Save
                  </Button>
                </Box>
              </Card>
            </Paper>
          </div>
        </Card>
      </Container>
    </Page>
  );
};
