import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Divider, Drawer, Hidden, List, makeStyles} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  DollarSign as DollarSign,
  FileText as FileText,
  Video as Video,
  Star as Star,
  Layers as Layers,
  MessageSquare as MessageSquare,
  Share2 as Share2,
  Users as UsersIcon,
  Settings
} from 'react-feather';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/rules',
    icon: Layers,
    title: "Referral Rules"
  },
  {
    href: '/app/appInstalls',
    icon: UsersIcon,
    title: "App Installs"
  },
  {
    href: '/app/smsLogs',
    icon: MessageSquare,
    title: "Sms Logs"
  },
  {
    href: '/app/messageConfig',
    icon: FileText,
    title: "Configurable Messages"
  },
  {
    href: '/app/viewGraph',
    icon: Share2,
    title: "View Graph"
  },
  {
    href: '/app/leaderBoard',
    icon: Star,
    title: "LeaderBoard"
  },
  {
    href: '/app/creditHistory',
    icon: DollarSign,
    title: "Credit History"
  },
  {
    href: '/app/styli-earn-videos',
    icon: Video,
    title: "Styli Earn Videos"
  },
  {
    href: '/app/settings',
    icon: Settings,
    title: "Settings"
  },
  // {
  //   href: '/app/ruleSummary',
  //   icon: UsersIcon,
  //   title: "Rule Summary"
  // },
  // {
  //   href: '/app/products',
  //   icon: ShoppingBagIcon,
  //   title: 'Products'
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Account'
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({onMobileClose, openMobile}) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Divider/>
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1}/>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{paper: classes.mobileDrawer}}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{paper: classes.desktopDrawer}}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default NavBar;
