import React, {useEffect, useState} from 'react';
import {Box, Container, Typography, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import MetaInfo from './MetaInfo';
import axios from "../../../utils/axios"

import Card from "@material-ui/core/Card";
import RuleConditions from "./RuleConditions";
import {servicePath} from '../../../constants/index';
import Terms from "./Terms";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import {useStyles} from '../../../constants/style';

const EditView = ({className}) => {
  const classes = useStyles();

  const pageURL = window.location.href;
  const lastSeg = pageURL.substr(pageURL.lastIndexOf('/') + 1);
  const [ruleId] = useState(lastSeg);
  const [ruleMeta, setRuleMeta] = useState(undefined);

  useEffect(() => {

    const fetchData = async () => {
      console.log(process.env, 'process.env')

      const result = await axios.post(`${servicePath}/getRuleInfo`, {ruleId: ruleId});
      console.log(result, 'result')
      result && setRuleMeta(result.data);
    };

    fetchData();
  }, []);


  return (
    <Page
      className={classes.root}
      title="Edit Referral Rule"
    >
      <Container maxWidth={false}>
        <Card style={{marginBottom: '20px'}}>
          <MetaInfo ruleMeta={ruleMeta}/>
        </Card>
        {ruleMeta?.IsVisible ?
        <><CardHeader
          title="Terms"
          subheader={`Modify Terms Text`}
        />
        <Divider/></>
        : ""}
        {['sa', 'ae', 'kw', 'qa', 'bh'].map((code) => {
          return ruleMeta?.Terms?.[code] && ruleMeta?.Terms?.[code].length ?
            <Card style={{marginBottom: '20px'}}>
              <Terms ruleMeta={ruleMeta} code={code} />
            </Card>
            : ""
        })}

        <Card style={{marginBottom: '20px'}}>
          <RuleConditions ruleMeta={ruleMeta}/>
        </Card>
      </Container>
    </Page>
  );
};

export default EditView;
