import {makeStyles} from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: '100%',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    capitalize: {textTransform: "capitalize"},
    tableRow: {
      cursor: "pointer",
    }
  }));