import React from 'react';
import {Navigate} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import EditView from 'src/views/edit/EditView';
import AppInstalls from 'src/views/edit/AppInstalls';
import SmsLogs from 'src/views/edit/SmsLogs';
import SummaryText from 'src/views/edit/SummaryText';
import RuleSummary from 'src/views/edit/RuleSummary';
import MessageConfig from 'src/views/edit/MessageConfig';
import ConnectionsGraph from 'src/views/Neo4j/GraphView';
import LeaderBoard from 'src/views/LeaderBoard';
import CreditHistory from 'src/views/edit/CreditHistory';
import StyliEarnVideos from 'src/views/styli-earn-videos';
import VerifyOTP from 'src/views/auth/verify-otp';

const routes = isLoggedIn => [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout/> : <Navigate to="/login"/>,
    children: [
      {path: 'account', element: <AccountView/>},
      {path: 'rules', element: <CustomerListView/>},
      {path: 'appInstalls', element: <AppInstalls/>},
      {path: 'creditHistory', element: <CreditHistory/>},
      {path: 'smsLogs', element: <SmsLogs/>},
      {path: 'summaryText', element: <SummaryText/>},
      {path: 'ruleSummary', element: <RuleSummary/>},
      {path: 'messageConfig', element: <MessageConfig/>},
      {path: 'dashboard', element: <DashboardView/>},
      {path: '/', element: <DashboardView/>},
      {path: 'products', element: <ProductListView/>},
      {path: 'viewGraph', element: <ConnectionsGraph/>},
      {path: 'styli-earn-videos', element: <StyliEarnVideos/>},
      {path: 'leaderBoard', element: <LeaderBoard/>},
      {path: 'settings', element: <SettingsView/>},
      {path: 'edit/:ruleId', element: <EditView/>},
      {path: 'add/:ruleId', element: <EditView/>},
      {path: '/', element: <Navigate to="/404"/>}
    ]
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout/> : <Navigate to="/app/dashboard"/>,
    children: [
      {path: 'login', element: <LoginView/>},
      {path: 'register', element: <RegisterView/>},
      {path: '404', element: <NotFoundView/>},
      {path: '/', element: <LoginView/>},
      {path: 'verify-otp', element: <VerifyOTP/>},
    ]
  }
];

export default routes;
