import React, {useEffect, useState} from 'react';
import {Box, Container, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import axios from "../../../utils/axios"

import {servicePath} from '../../../constants/index';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [rules, setRules] = useState([]);
  const [initRules, setInitRules] = useState([]);
  useEffect(() => {
    console.log(process.env, 'process.env')

    const fetchData = async () => {
      const result = await axios(`${servicePath}/getRulesMeta`);
      setRules(result.data);
      setInitRules(result.data)
    };

    fetchData();
  }, []);

  const filterRules = (e) => {
    console.log(e.target.value, '@@str@@')
    let str = e.target.value;
    setRules(initRules.filter((rule) => rule.Description && rule.Description.toLowerCase().includes(str.toLowerCase())));
  }

  return (
    <Page
      className={classes.root}
      title="Referral Rules"
    >
      <Container maxWidth={false}>
        <Toolbar filterRules={filterRules}/>
        <Box mt={3}>
          <Results rules={rules} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
