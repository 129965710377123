import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, makeStyles, TextField} from '@material-ui/core';

import {Snackbar,Typography} from '@material-ui/core';
import axios from "../../../utils/axios"

import Alert from "@material-ui/lab/Alert";
import {servicePath} from '../../../constants/index';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
const dot = require('dot-object');

const useStyles = makeStyles((theme) => ({
  formControl: {
    fullWidth: true,
    minWidth: 120,
    display: 'flex',
    wrap: 'nowrap'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
const MenuItemsMap = () => {
  return {
    "Type": [
      "referred-app-install", "signup"
    ],
    "OfferExpiry": ["30days", "Within 1 week of the first order"],
    "OrderStatus": [true, false],
    "NoOfReferrals": [1, 2, 3, 4, 5, 6, 7, 8, 9, ">=10"],
    "ReferredPosition": [1, 2, 3, 4, 5, 6, 7, 8, 9, ">=10"],
    "OrderRank": [1, 2, "3", 4, 5, 6, 7, 8, 9, ">=10"],
    "OrderAmountThreshold": [">=10", ">=30", ">=100"],
    "CoinOrderAmountThreshold": [">=10", ">=30", ">=100"],
    "Activity": ["referred-app-install", "app-install", "signup", "order", "redeem", "login", "order"]
  }
}
const getMenuItems = (key) => {
  return MenuItemsMap()[key]?.map((val, i) => (<MenuItem key={i} value={val}>{val.toString()}</MenuItem>))
}

const RuleConditions = ({className, ruleMeta, ...rest}) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [conditionType, setConditionType] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [orderRule, setOrderRule] = useState(false);

  const jsonRuleMeta = JSON.stringify(ruleMeta);

  useEffect(() => {
    console.log('Setting again @useEffect')
    if (ruleMeta) {
      setValues({...ruleMeta.Conditions})
      setConditionType(ruleMeta?.Conditions?.Type);
      if (ruleMeta?.Conditions?.Type == "order") {
        setOrderRule(true);
      } else {
        setOrderRule(false);
      }
    }
  }, [jsonRuleMeta]);

  const handleChange = (event) => {
    console.log(event.target.value, '@handleChange');
    console.log(values, '@handleChange - Values');

    if (event.target.name == "Type") {
      setConditionType(event.target.value);
      if (event.target.value == "order") {
        setOrderRule(true);
      } else {
        setOrderRule(false);
      }
    }

    console.log(event.target.value, typeof event.target.value, "event.target.value")
    let row = {[event.target.name]: event.target.value};
    row = dot.object(row);
    const key = Object.keys(row)[0];
    if (typeof row[key] == 'object') {
      row[key] = {...values[key], ...row[key]}
    }
    setValues({
      ...values,
      ...row
    });
  };

  const saveRuleInfo = async () => {
    console.log("Save Rule Info");
    const updReq = {...ruleMeta};
    const inpReq = values;
    for (const v of ['AED', 'KWD', 'SAR', 'QAR', 'BHD']){
      inpReq["OrderAmountThreshold"][v] = parseFloat(inpReq["OrderAmountThreshold"][v]);
      if(isNaN(inpReq["OrderAmountThreshold"][v])){
        inpReq["OrderAmountThreshold"][v] = 0 ;
      }
    }
    for (const v of ['AED', 'KWD', 'SAR', 'QAR', 'BHD']){
      inpReq["CoinOrderAmountThreshold"][v] = parseFloat(inpReq["CoinOrderAmountThreshold"][v]);
      if(isNaN(inpReq["CoinOrderAmountThreshold"][v])){
        inpReq["CoinOrderAmountThreshold"][v] = 0 ;
      }
    }
    const updConditions = {...inpReq};
    console.log(updConditions, "@@@updConditions@@@")

    updReq.Conditions = updConditions;
    updReq['ruleId'] = ruleMeta.ID;
    const result = await axios.post(`${servicePath}/saveRuleInfo`, updReq);

    if (result.status) {
      setOpenSnack(true)
    }
  }

  if (!ruleMeta) {
    return <p>No Rule info.</p>
  }


  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
        closeFunc={() => {
          setOpenSnack(false)
        }}
        message="Update successful.">
        <Alert onClose={() => {
          setOpenSnack(false)
        }} severity="success">
          <Box style={{fontWeight: "bold"}}> Update successful</Box>
        </Alert>
      </Snackbar>
      <form
        className={clsx(classes.root, className)}
        {...rest}
      >

        <Card>
          <CardHeader
            title="Rule Conditions"
            subheader={`Modify Rule conditions`}
          />
          <Divider/>
          <CardContent style={{padding: "30px"}}>
            <Grid container spacing={6} wrap="wrap">
              <Grid item md={6} xs={12} key={"Type"}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    style={{width: "100%"}}
                    name={"Type"}
                    disabled={ruleMeta.IsVisible ? true : false}
                    value={conditionType}
                    width={"100%"}
                    helperText="Rule Type - Activity on which rule should be checked."
                    onChange={handleChange}
                  >
                    <MenuItem key={"referred-app-install"} value={"referred-app-install"}>Referred App
                      Install</MenuItem>
                    <MenuItem key={"videos-app-install"} value={"videos-app-install"}>Videos App
                      Install</MenuItem>
                    <MenuItem key={"signup"} value={"signup"}>Signup</MenuItem>
                    <MenuItem key={"videos-signup"} value={"videos-signup"}>Videos Signup</MenuItem>
                    <MenuItem key={"order"} value={"order"}>Order</MenuItem>
                  </Select>
                  <FormHelperText>Rule Type - Activity on which rule should be checked.</FormHelperText>
                </FormControl>
              </Grid>
              {
              orderRule ?
              <Grid item md={6} xs={12} key={"Type"}>
                <TextField
                  style={{padding: "0 0px"}}
                  fullWidth
                  label="Order Number"
                  name="OrderRank"
                  onChange={handleChange}
                  required
                  value={values.OrderRank}
                  variant="outlined"
                />
              </Grid>
              : ""}
            </Grid>
            {
              orderRule ?
              <>
             
                  <Divider/>
                  <Typography
              color="textPrimary"
              gutterBottom
              variant="h6"
              style={{ marginTop: '10px' }}
            >
              Store Credit
            </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding={"20px 0"}
                >
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (SAR)"
                    name="OrderAmountThreshold.SAR"
                    onChange={handleChange}
                    required
                    value={values.OrderAmountThreshold?.SAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (AED)"
                    name="OrderAmountThreshold.AED"
                    onChange={handleChange}
                    required
                    value={values.OrderAmountThreshold?.AED}
                    variant="outlined"
                  />
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (KWD)"
                    name="OrderAmountThreshold.KWD"
                    onChange={handleChange}
                    required
                    value={values.OrderAmountThreshold?.KWD}
                    variant="outlined"
                  />
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (QAR)"
                    name="OrderAmountThreshold.QAR"
                    onChange={handleChange}
                    required
                    value={values.OrderAmountThreshold?.QAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (BHD)"
                    name="OrderAmountThreshold.BHD"
                    onChange={handleChange}
                    required
                    value={values.OrderAmountThreshold?.BHD}
                    variant="outlined"
                  />
                  </Box>
                  <Divider/>
                  <Typography
              color="textPrimary"
              gutterBottom
              variant="h6"
              style={{ marginTop: '10px' }}
            >
            Styli Coin
            </Typography>
                  <Box
                  display="flex"
                  justifyContent="space-between"
                  padding={"20px 0"}
                >
                  <Divider/>
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (SAR)"
                    name="CoinOrderAmountThreshold.SAR"
                    onChange={handleChange}
                    required
                    value={values.CoinOrderAmountThreshold?.SAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (AED)"
                    name="CoinOrderAmountThreshold.AED"
                    onChange={handleChange}
                    required
                    value={values.CoinOrderAmountThreshold?.AED}
                    variant="outlined"
                  />
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (KWD)"
                    name="CoinOrderAmountThreshold.KWD"
                    onChange={handleChange}
                    required
                    value={values.CoinOrderAmountThreshold?.KWD}
                    variant="outlined"
                  />
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (QAR)"
                    name="CoinOrderAmountThreshold.QAR"
                    onChange={handleChange}
                    required
                    value={values.CoinOrderAmountThreshold?.QAR}
                    variant="outlined"
                  />
                  <TextField
                    style={{padding: "0 25px 0 0"}}
                    fullWidth
                    label="Order Amount threshold (BHD)"
                    name="CoinOrderAmountThreshold.BHD"
                    onChange={handleChange}
                    required
                    value={values.CoinOrderAmountThreshold?.BHD}
                    variant="outlined"
                  />
                </Box>
                </>
                : ""}
          </CardContent>
          <Divider/>
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={saveRuleInfo}
            >
              Save
            </Button>
          </Box>
        </Card>
      </form>
    </>
  )
    ;
};

export default RuleConditions;
