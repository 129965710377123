import React, {useContext, useEffect, useState} from 'react';
import {Container, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import Budget from './Budget';
import axios from "../../../utils/axios"

import {servicePath} from "../../../constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {GlobalFilterCtx} from "../../../App";
import DashboardSection from "./DashboardSection";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    flex: '1 1 100%', color: "#546e7a",
    fontFamily: "'Roboto'",
    fontWeight: 500,
    fontSize: "20px",
    padding: "20px 0"
  },

}));

const Dashboard = () => {
  const classes = useStyles();
  const [dashboardValues, setDashboardValues] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const {store, date} = useContext(GlobalFilterCtx);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {

      const result = await axios(`${servicePath}/getStatsForDashboard`);
      console.log(result, 'result')
      result && setDashboardValues(result.data);
      setIsLoading(false);
    };

    fetchData();
  }, [store, date]);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        {isLoading && <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-50px",
          marginLeft: "-50px",
          zIndex: "1000",
          width: "100px",
          height: "100px"
        }}><CircularProgress/></div>}
        <Box>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Ivy Stats
          </Typography>
        </Box>
        <Box
          container
          spacing={3}
          style={{padding: "5px", display: "flex", justifyContent: "space-between"}}
        >
          <Budget dashboardValues={dashboardValues} type={"TotalRules"} dname={"TOTAL RULES"}/>
          <Budget dashboardValues={dashboardValues} type={"TotalSMS"} dname={"SMS SENT"}/>
          <Budget dashboardValues={dashboardValues} type={"TotalReferrers"} dname={"TOTAL REFERRERS"}/>
          <Budget dashboardValues={dashboardValues} type={"TotalAppInstalls"} dname={"APP INSTALLS"}/>
          <Budget dashboardValues={dashboardValues} type={"TotalSignUps"} dname={"TOTAL SIGNUPS"}/>
        </Box>
        <DashboardSection title="Credit Amount Breakup" type="TotalCreditByStore" classes={classes} dashboardValues={dashboardValues} />
        <DashboardSection title="Coin Amount Breakup" type="TotalCoinByStore" classes={classes} dashboardValues={dashboardValues} />
        
        

        <Box
          container
          spacing={3}
          style={{padding: "5px", display: "flex", justifyContent: "space-evenly"}}
        >

        </Box>
      </Container>
    </Page>
  );
};

export default Dashboard;
